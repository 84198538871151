import React from 'react';
import Layout from 'components/layout';

import bgImage from 'images/about.png';

const NotFoundPage = () => (
    <Layout>
        <div className="fluid-container page-container about-container">
            <div className="container">
                <img src={bgImage} className="bg-image" />
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-6 about-content">
                        <h2 className="page-title">Not Found</h2>
                        <p className="lead-in">
                            The page you&#39;re looking for doesn&#39;t exist.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default NotFoundPage;
